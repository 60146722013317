export const SCRIPT_OPTIONS = {
  CookieDeclaration: 'Cookie Declaration',
};
export type ScriptType = keyof typeof SCRIPT_OPTIONS;

export const SCRIPTS: Record<ScriptType, {}> = {
  CookieDeclaration: {
    id: 'CookieDeclaration',
    src: 'https://consent.cookiebot.com/e28f9653-5a8d-478d-8764-010b3403cad0/cd.js',
    async: true,
    wrapperId: 'CookieDeclaration',
  },
};
