import cx from 'classnames';
import React from 'react';

export type FigCaptionProps = {
  caption: string;
  className?: string;
};

export const FigCaption = ({ caption, className }: FigCaptionProps) => {
  if (!caption?.trim().length) return null;
  return (
    <figcaption
      className={cx(
        'mt-3 text-left text-md leading-relaxed prose-current text-neutral-25',
        className,
      )}
    >
      {caption}
    </figcaption>
  );
};

export default React.memo(FigCaption);
