import { PortableTextBlockComponent } from '@portabletext/react';
import {
  Accordion as PyxisAccordion,
  AccordionProps as PyxisAccordionProps,
} from '@pyxis/react';
import { AccordionItemProps as PyxisAccordionItemProps } from '@pyxis/react/dist/components/Accordion/AccordionItem';
import dynamic from 'next/dynamic';

import { PortableTextProps } from '../content/PortableText';
import PyxisIcon from '../images/PyxisIcon';
import { PyxisIconType } from '../images/PyxisIconOptions';

const PortableText = dynamic<PortableTextProps>(
  () =>
    import(
      /* webpackChunkName: "PortableText" */ '../../components/content/PortableText'
    ) as any,
);

export type AccordionProps = Partial<PyxisAccordionProps> & {
  items?: ({
    content?: PortableTextBlockComponent[];
    icon?: PyxisIconType;
    defaultExpanded?: boolean;
  } & PyxisAccordionItemProps)[];
};
export const Accordion = ({ items, ...rest }: AccordionProps) => {
  if (!Boolean(items?.filter(Boolean).length)) return null;

  let defaultExpanded =
    rest.defaultExpanded ||
    items
      .filter(({ defaultExpanded }) => Boolean(defaultExpanded))
      .map(({ title }) => title);

  return (
    <PyxisAccordion {...rest} defaultExpanded={defaultExpanded}>
      {items?.map((item) => (
        <PyxisAccordion.Item
          {...item}
          id={item.title}
          key={item.title}
          addon={
            item.icon ? ((() => <PyxisIcon name={item.icon} />) as any) : item.addon
          }
        >
          <PortableText content={item.content} />
        </PyxisAccordion.Item>
      ))}
    </PyxisAccordion>
  );
};

export default Accordion;
