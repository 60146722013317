import { PortableText as PortableTextReact } from '@portabletext/react';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import React from 'react';

import { type VideoType } from '../../types';
import Accordion from '../Accordion/Accordion';
import { Downloads } from '../Downloads/Downloads';
import { Table } from '../Table/Table';
import { ButtonCardGroup } from '../buttons/ButtonCardGroup';
import { ButtonGroup } from '../buttons/ButtonGroup';
import { Link } from '../buttons/Link';
import FigCaption from '../images/FigCaption';
import { IconLoader } from '../images/IconLoader';
import { ResponsiveImage } from '../images/ResponsiveImage';
import { ZoomableImage } from '../images/ZoomableImage';
import { SCRIPTS } from './PortableTextOptions';

const Video = dynamic<VideoType>(
  () =>
    import(
      /* webpackChunkName: "VideoComponent" */ '../../components/video/Video'
    ) as any,
);

export type PortableTextProps = {
  content: any[];
};

export const PortableText = ({ content = [] }) => {
  if (!content) return null;
  return (
    <PortableTextReact
      value={content}
      components={{
        block: {
          small: ({ children }) => <p className="prose-sm">{children}</p>,
        },
        marks: {
          link: ({ value, children }) => {
            return (
              <Link href={value.href} locale={value.language} target={value.target}>
                {children}
              </Link>
            );
          },
        },
        list: {
          bullet: ({ children }) => <ul>{children}</ul>,
          number: ({ children }) => <ol>{children}</ol>,
        },
        types: {
          'image.simple'({ value }) {
            return (
              <div className="not-prose my-8 md:my-12 lg:my-16 [&:first-child]:!mt-0 prose-media">
                <figure>
                  <ZoomableImage {...value} enabled={value.zoomable} />
                  {value?.image?.src && value?.downloadable && (
                    <a
                      href={`${value.image.src}?dl`}
                      target="_blank"
                      rel="noreferrer"
                      download
                      className="group ml-3 mb-2 mt-3 inline-block float-right border-2 border-action-base hover:border-action-dark transition-colors rounded-full inline-flex p-3"
                    >
                      <IconLoader
                        icon="download"
                        className="w-5 h-5 text-action-base group-hover:text-action-dark transition-colors block"
                      />
                    </a>
                  )}
                  <FigCaption
                    caption={value.caption}
                    className="pl-10 md:pl-16 lg:pl-0"
                  />
                </figure>
              </div>
            );
          },
          video({ value }) {
            return (
              <div className="not-prose my-8 md:my-12 lg:my-16 prose-media">
                <figure>
                  <Video {...value.video} />
                  <FigCaption caption={value.caption} />
                </figure>
              </div>
            );
          },
          buttons({ value }) {
            return (
              <div className="not-prose my-8">
                <ButtonGroup {...value} />
              </div>
            );
          },
          csv({ value }) {
            return <Table {...value} />;
          },
          downloads({ value }) {
            return <Downloads {...value} />;
          },
          scripts({ value }) {
            if (!SCRIPTS[value?.scriptId]) return null;
            return (
              <div id={SCRIPTS[value?.scriptId]?.wrapperId}>
                <Script {...SCRIPTS[value?.scriptId]} />
              </div>
            );
          },
          accordion({ value }) {
            return (
              <div className="not-prose my-8">
                <Accordion {...value} />
              </div>
            );
          },
          buttonCardGroup({ value }) {
            return (
              <div className="not-prose my-8">
                <ButtonCardGroup {...value} />
              </div>
            );
          },
        },
      }}
    />
  );
};

export default React.memo(PortableText);
