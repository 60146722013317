import {
  ButtonCard,
  IconDocumentSigned,
  IconDownload,
  IconExternalLink,
} from '@pyxis/react';
import { ButtonCardProps } from '@pyxis/react/dist/components/ButtonCard/ButtonCard';

import { LanguageType } from '../../languages';
import PyxisIcon from '../images/PyxisIcon';
import { PyxisIconType } from '../images/PyxisIconOptions';

export type ButtonCardGroupProps = {
  items?: (Partial<ButtonCardProps> & {
    icon?: PyxisIconType;
    link?: {
      download?: boolean;
      target?: string;
      language?: LanguageType;
      href?: string;
    };
  })[];
};

export const ButtonCardGroup = ({ items }: ButtonCardGroupProps) => {
  if (!Boolean(items?.filter(Boolean).length)) return null;

  return (
    <div className="flex gap-4 flex-wrap">
      {items?.map((item) => {
        let icon: PyxisIconType = item.icon;
        if (!icon) {
          if (item.link?.href?.startsWith('http')) icon = 'IconExternalLink';
          if (item.link?.download) icon = 'IconDownload';
        }

        return (
          <ButtonCard
            key={item.title}
            {...item.link}
            icon={(() => <PyxisIcon name={icon} size="l" />) as any}
          >
            {item.children || item.title}
          </ButtonCard>
        );
      })}
    </div>
  );
};
