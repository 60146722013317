import cx from 'classnames';
import React, { useContext, useState } from 'react';

import { PageContext } from '../../context/PageContext';
import { formatBytes, getFileType } from '../../helpers/utils/file';
import { LanguageType } from '../../languages';
import { Button } from '../buttons/Button';
import { Link } from '../buttons/Link';

export type DownloadType = {
  title?: string;
  link?: string;
  file?: string;
  filesize?: string;
  filename?: string;
  language?: LanguageType;
};

export type DownloadsProps = {
  items?: DownloadType[];
};

const ITEMS_PER_PAGE = 6;

export const Downloads = ({ items }: DownloadsProps) => {
  const { config, locale } = useContext(PageContext);
  const translations = config.translations;
  const [currentPage, setCurrentPage] = useState<number>(1);

  if (!items?.length) return null;

  const filteredItems = [...items].slice(0, currentPage * ITEMS_PER_PAGE);
  const hasNext = items.length > currentPage * ITEMS_PER_PAGE;

  return (
    <div className="not-prose my-12 prose-media">
      <ul className="flex flex-col gap-3 md:gap-0 p-0 m-0">
        {filteredItems
          .filter(Boolean)
          .map(({ title, link, file, filesize, filename }, index) => (
            <li
              key={title}
              className={cx(
                'relative rounded-xl px-4 md:px-8 py-4 md:py-6 group w-full',
                'transition-all hover:shadow-lg focus-within:shadow-lg duration-500',
                'shadow-lg md:shadow-none',
                'bg-white md:bg-transparent',
                'hover:bg-white focus-within:bg-white hover:z-10',
                'flex flex-col md:flex-row gap-2 md:gap-8 md:items-center text-left',
                'md:-mt-0.5',
              )}
            >
              <div>
                {title && (
                  <Link href={file || link} className="outline-none" download>
                    <span className="absolute inset-0 bg-neutral-25 block opacity-0 z-10 cursor-pointer" />
                    <span className="sr-only">{title}</span>
                  </Link>
                )}
                {title && (
                  <h3
                    className={cx(
                      'font-bold text-neutral-base text-title-sm-sm md:text-title-md-md lg:text-title-md-lg',
                      'group-hover:underline group-focus-within:underline underline-offset-4 md:group-hover:no-underline md:group-focus-within:no-underline',
                    )}
                  >
                    {title}
                  </h3>
                )}

                <span className="font-bold text-title-sm-sm md:text-title-sm-md lg:text-title-sm-lg text-brand-base uppercase">
                  {[getFileType(filename || link), formatBytes(filesize)]
                    .filter(Boolean)
                    .join(', ')}
                </span>
              </div>

              <div className="md:ml-auto shrink-0">
                <Button
                  as="span"
                  label={translations?.download_list_button?.[locale] || 'Download'}
                  variant="secondary"
                />
              </div>

              {/* Trick to add bottom border. Can't use border due to box shadow and border radius */}
              {index !== items?.length - 1 && (
                <span className="hidden md:block h-0.5 bg-neutral-85 absolute left-0 right-0 bottom-0 group-hover:opacity-0 transition-opacity duration-500" />
              )}

              {/* Trick to 'hide' previous item bottom border when hovering. Can't use background color due to shadow and border radius */}
              {index > 0 && (
                <span className="hidden md:block h-0.5 bg-neutral-95 absolute left-0 right-0 top-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              )}
            </li>
          ))}
      </ul>

      {hasNext && (
        <div className="text-center my-10">
          <Button
            label={translations?.download_list_show_more?.[locale]}
            onClick={() => setCurrentPage((n) => n + 1)}
          />
        </div>
      )}
    </div>
  );
};
