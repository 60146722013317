import { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { ResponsiveImage, ResponsiveImageProps } from './ResponsiveImage';

export type ZoomableImageProps = {
  enabled?: boolean;
  image?: ResponsiveImageProps;
};

export const ZoomableImage = ({ enabled, image }: ZoomableImageProps) => {
  const [zoomEnabled, setZoomEnabled] = useState(false);

  if (!image) return null;

  if (!enabled) return <ResponsiveImage {...image} />;

  return (
    <TransformWrapper disabled={!enabled || !zoomEnabled} maxScale={3}>
      {({ zoomIn, resetTransform, ...rest }) => (
        <div
          className="cursor-zoom-in"
          onClick={() => {
            if (zoomEnabled) return;
            setZoomEnabled(true);
            zoomIn();
          }}
          onMouseLeave={() => {
            setZoomEnabled(false);
            resetTransform();
          }}
        >
          <TransformComponent>
            <ResponsiveImage
              {...image}
              dpr={3}
              className="point-events-none select-none"
            />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};
