export function getFileType(url) {
  if (!url) return null;
  const splitted = url.split(/[#?]/)[0].split('.');
  if (!url.startsWith('/') && splitted.length === 3) return null;
  return splitted.pop().trim();
}

export function formatBytes(bytes, decimals = 1) {
  if (!+bytes) return null;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
